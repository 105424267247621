<template>
  <div>
    <load-profile></load-profile>
    <b-card>
      <validation-observer ref="editForm" #default="{ invalid }">
        <n-form-confirmation
          key="editForm"
          @submit="submit"
          :form="$refs.editForm"
          :disabled="invalid"
        >
          <n-input
            :fields="fields"
            v-model="data"
            :initValue="initData"
          ></n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :resource="resource"
                :route="route"
                :hide-create="true"
                v-if="$can('update', resource)"
              ></n-button-save-option>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>
  
<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";

const FooterRepository = Repository.get("footer");

export default {
  components: {
    BRow,
    BCol,
    BCard,

    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
  },
  directives: {
    Ripple,
  },
  computed: {},
  data() {
    return {
      data: {
        nameEn: null,
        nameKm: null,
        email: null,
        phoneNumber: null,
        latitude: null,
        longitude: null,
        addressEn: null,
        addressKm: null,
        facebookUrl: null,
        instagramUrl: null,
        linkedinUrl: null,
        telegramUrl: null
      },
      initData: {},
      loading: false,
      type: 2,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      FooterRepository.show().then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.initData = {
            ...data,
          };
        }
      });
    },
    submit(type) {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          type = this.type;
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;
      FooterRepository.update(this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.editForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `update-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    const resource = "footer";
    const route = "footer";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>