export default [
  {
    key: "nameEn",
    label: "field.nameEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "nameKm",
    label: "field.nameKm",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "email",
    label: "field.email",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "phoneNumber",
    label: "field.phoneNumber",
    rules: "required|max:20",
    type: "text",
  },
  {
    key: "latitude",
    label: "field.latitude",
    rules: "required|max:50",
    type: "text",
  },
  {
    key: "longitude",
    label: "field.longitude",
    rules: "required|max:50",
    type: "text",
  },
  {
    key: "addressEn",
    label: "field.addressEn",
    rules: "required|max:300",
    type: "textarea",
  },
  {
    key: "addressKm",
    label: "field.addressKm",
    rules: "required|max:300",
    type: "textarea",
  },
  {
    label: "breadcrumb.socialMedia",
    icon: "LinkIcon",
    type: "divider",
  },
  {
    key: "facebookUrl",
    label: "field.facebook",
    rules: "max:200",
    type: "text",
  },
  {
    key: "instagramUrl",
    label: "field.instagram",
    rules: "max:200",
    type: "text",
  },
  {
    key: "linkedinUrl",
    label: "field.linkedin",
    rules: "max:200",
    type: "text",
  },
  {
    key: "telegramUrl",
    label: "field.telegram",
    rules: "max:200",
    type: "text",
  },
  // {
  //   label: "breadcrumb.applicationAccount",
  //   icon: "LinkIcon",
  //   type: "divider",
  // },
  // {
  //   key: "vclaAppStoreUrl",
  //   label: "field.vclaAppStore",
  //   rules: "max:200",
  //   type: "text",
  // },
  // {
  //   key: "vclaPlayStoreUrl",
  //   label: "field.vclaPlayStore",
  //   rules: "max:200",
  //   type: "text",
  // },
];
